/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
// Components
import Content from 'components/Content'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactBox from 'components/ContactBox'
// Images
import arrowRight from 'img/arrow_right.svg'
// CSS
import 'styles/ServicesDetail.scss'
// Third party
import parse from 'html-react-parser'
import styled from 'styled-components'

const PointImgContainer = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100px;
`

const PointTextContainer = styled.div`
  @media (max-width: 992px) {
    width: 100%;
  }

  p {
    font-size: 16px;
    color: #2b2652;
    text-align: center;
    line-height: 22px;
  }
`

const PointContainer = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background-image: url(${arrowRight});
    height: 14px;
    width: 20px;
    top: 23%;
    right: -10px;
  }

  @media (max-width: 992px) {
    ::before {
      top: auto;
      bottom: 30px;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      transform: rotate(90deg);
    }
  }
`

const BigPointContainer = styled.div`
  top: -230px;
  right: -125px;

  p {
    width: 100%;
    text-align: center;
    color: #2b2652;
  }
  
  @media (max-width: 992px) {
    right: auto;
    top: auto;
  }

  @media (max-width: 450px) {
    img {
      height: 280px;
      width: 330px;
    }
  }

  @media (max-width: 400px) {
    img {
      height: 250px;
      width: 300px;
    }
  }
`

const PointContainerRow = styled.div`
  left: -30px;

  @media (max-width: 992px) {
    left: auto;
  }
`

const BigPointWrapper = styled.div`
  @media (max-width: 992px) {
    height: 400px;
  }

  @media (max-width: 450px) {
    height: 280px;
  }

  @media (max-width: 350px) {
    height: 250px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  @media (max-width: 991px) {
    p, strong {
      font-size: 20px !important;
    }
  }
`

const salesAndMerchandising = ({ data, pageContext }) => {
  const {
    wordpressPage: {
      title,
      acf: {
        sales_title_section: titleSection,
        sales_banner: salesBanner,
        sales_campagnes: salesCampagnes,
        sales_meer_informatie: moreInformation,
        actionable_insights: insights,
      },
    },
  } = data

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(to left, rgba(0, 53, 120, 0) 32%, #001843)`,
    ].reverse()

    return gradient
  }

  return (
    <Layout extraFooterPiece>
      <SEO title={title} />
      <div className="service">
        <section className="empty-space-50 color-background-main d-flex align-items-center">
          <div className="container">
            <p className="text-white font-family-main font-size-xm mb-0">{parse(title)}</p>
          </div>
        </section>

        <section className="title mb-5">
          <div className="container mt-2">
            <div className="mb-5">
              <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={parse(title)} />
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex align-items-center mb-4">
                  <img src={titleSection.image.localFile.publicURL} alt="" />
                  <h1 className="ml-4 mb-0 font-weight-xl font-size-h1">{titleSection.title}</h1>
                </div>
                <Content content={titleSection.description} />
              </div>
              <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
                <ContactBox className="pt-5 mt-4" content={moreInformation} />
              </div>
            </div>
          </div>
        </section>

        <div className="color-background-secondary py-5">
          <div className="container">
            <h2 className="m-0 pb-3 p-0 col-12 font-size-26 font-weight-xl color-text-main">{insights.Title}</h2>
            <p className="font-size-m">{insights.sub_title}</p>

            <div className="row pt-5 pb-3">
              <div className="col-12 col-lg-8">
                <PointContainerRow className="position-relative row">
                  {insights.points.map((point, index) => (
                    <>
                      {index <= 3 && (
                        <PointContainer
                          className="col-12 col-lg-3 pb-lg-0 pb-5 d-flex justify-content-center justify-content-lg-start flex-wrap"
                          key={index}
                        >
                          <div className="d-flex justify-content-center flex-wrap">
                            <PointImgContainer
                              className="d-flex justify-content-center align-items-center color-background-light"
                            >
                              <img src={point.icon.localFile.publicURL} alt="" />
                            </PointImgContainer>
                            <PointTextContainer className="pt-3 d-flex justify-content-center">
                              <Content content={point.text} />
                            </PointTextContainer>
                          </div>
                        </PointContainer>
                      )}
                    </>
                  ))}
                </PointContainerRow>
              </div>
              <BigPointWrapper className="col-12 col-lg-4 d-flex justify-content-center position-relative">
                <BigPointContainer className="position-absolute d-flex justify-content-center flex-wrap">
                  <img className="pb-4" src={insights.points[4].icon.localFile.publicURL} alt="" />
                  <Content className="w-100" content={insights.points[4].text} alt="" />
                </BigPointContainer>
              </BigPointWrapper>
            </div>
          </div>
        </div>


        <StyledBackgroundImage
          fluid={addGradient(salesBanner.image.localFile.childImageSharp.fluid)}
          className="background-banner mb-5"
          tag="section"
        >
          <div className="container py-5">
            <Content
              className="nested py-lg-5 my-3 font-weight-xl color-text-light font-size-h1 line-height-s"
              content={salesBanner.text}
            />
          </div>
        </StyledBackgroundImage>

        <div className="container mb-5">
          {salesCampagnes.map((campagne, index) => (
            <div className={`row mb-5 ${index % 2 ? 'flex-row-reverse' : 'flex-row'}`}>
              <div className="col-lg-6">
                <GatsbyImage fluid={campagne.image.localFile.childImageSharp.fluid} />
              </div>
              <div className="col-lg-6 pt-5 pl-5 pr-5">
                <h2 className="font-weight-xl font-size-l mb-4">{parse(campagne.title)}</h2>
                <Content content={campagne.description} />
              </div>
            </div>
          ))}
        </div>
        <div className="empty-space-100 mb-5" />
      </div>
    </Layout>

  )
}

export default salesAndMerchandising

export const pageQuery = graphql`
	query SalesAndMerchandisingQuery($wordpress_id: Int!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
			acf {
				sales_title_section {
					image {			
            localFile {
              publicURL
            }
					}
					title
					description
				}

				sales_banner {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					text
				}

				sales_campagnes {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 620) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					title
					description
				}

				sales_meer_informatie {
					title
					sub_title
					button_text
					link_title
					link_group {
						link {
							url
							title
						}
					}
				}

				actionable_insights {
					Title
					sub_title
					points {
						text
						icon {
              localFile {
                publicURL
              }
						}
					}
				}

			}
		}
	}
`